:root {
    --text-base-size: 1em;
    --text-scale-ratio: 1.2;
    --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
    --text-sm: calc(1em / var(--text-scale-ratio));
    --text-md: calc(1em * var(--text-scale-ratio));
    --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xxl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xxxl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
    --heading-line-height: 1.2;
    --body-line-height: 1.4;
    --space-xxxs: 0.25em;
    --space-xxs:  0.375em;
    --space-xs:   0.5em;
    --space-sm:   0.75em;
    --space-md:   1.25em;
    --space-lg:   2em;
    --space-xl:   3.25em;
    --space-xxl:  5.25em;
    --space-xxxl: 8.5em;
}

html,
body,
header,
footer,
main,
article,
address,
aside,
div,
p,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
hr {
    margin: 0;
    padding: 0;
}

body {
    font-size: var(--text-base-size);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Hoefler Text", Georgia, serif;
}

h1,
h2,
h3,
h4,
form legend {
    color: #111;
    margin-bottom: var(--space-xxs);
    line-height: var(--heading-line-height);
}

h1 {
    margin-bottom: 0;
}

hr {
    border: 1px solid rgba(128, 133, 193, 0.25);
}

pre,
code {
    font-family: Monaco, Consolas, "Lucida Console", monospace;
}

code {
    font-size: 92%;
}

pre {
    margin-left: var(--space-md);
    margin-right: var(--space-md);
    overflow: scroll;
}

ul,
ol,
p,
hr {
    margin: var(--space-md) 0;
}

b,
strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

u {
    text-decoration: underline;
}

address {
    font-style: normal;
}

.text--xxxl {
    font-size: var(--text-xxxl);
}

h1,
.text--xxl {
    font-size: var(--text-xxl);
}

h2,
.text--xl {
    font-size: var(--text-xl);
}

h3,
.text--lg {
    font-size: var(--text-lg);
}

h4,
.text--md {
    font-size: var(--text-md);
}

.text--sm,
small {
    font-size: var(--text-sm);
}

.text--xs {
    font-size: var(--text-xs);
}

p,
.prose {
    line-height: var(--body-line-height);
}

article h2,
article h3,
article h4 {
    margin-top: var(--space-sm);
}

article p {
    text-align: justify;
}

article ul,
article ol {
    list-style-position: outside;
    padding-left: 24px;
}

article ul {
    list-style-type: disc;
}

article ol {
    list-style-type: decimal;
}

article ul li,
article ol li {
    line-height: var(--body-line-height);
}

article img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

a {
    color: #c22a09;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:visited {
    color: #c22a09;
}

.deemphasized {
    color: #aaa;
}

.deemphasized a {
    color: #8085c1;
}

.label {
    color: #777;
}

.no-bullets {
    list-style-type: none;
    padding-left: 0;
}

.no-bullets li+li {
    margin-top: var(--space-xs);
}

.clear {
    clear: both;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.align-right {
    text-align: right;
}

.container {
    margin: 0 auto;
    padding: 0 25px;
    max-width: 700px;
}

header.top {
    margin: var(--space-lg) 0;
}

footer.bottom {
    margin-bottom: var(--space-lg);
}
